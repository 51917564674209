import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[2];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  Güero’s is a can't miss option across the board, for many
                  reasons, but we adored their vegan and gluten-free options the
                  most. We loved the tacos de salud, one of their many vegan
                  taco options, that comes with soy chorizo topped with onion,
                  cilantro and pineapple. The restaurant is operated by the
                  Lippincott sisters and was originally start by their parents,
                  Rob and Cathy. This taco bar really is a great combinaion of
                  interior Mexican food and Tex-Mex for the perfect blend.
                </p>
                <p>
                  Guero’s is know for being both a tourist destination, as well
                  as a gem for Austin locals. As it is one of Austin's most
                  established restaurants, it is located in South Congress near
                  several other businesses. This location pretty much has
                  everything you need: live music, a full bar, and time-tested
                  menu options. Oh, and did we mention.. catering :)
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
